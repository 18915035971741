<template>
  <div class="dropdown-container" v-clickoutside="close">
    <button :disabled="this.disabled" :class="['button button-dropdown is-accent', buttonClass]" @click="open = !open">
      <span>
        {{buttonText}}
      </span>
      <span >
        <i v-if="!open" class="fas fa-caret-down"></i>
        <i v-if="open" class="fas fa-caret-up"></i>
      </span>
    </button>
    <div v-show="open" class="button-dropdown-container">
      <div class="" v-for="option in options" :key="option.name" style="width: 100%; z-index: 9999;">
        <button :disabled="option.disabled" class="button button-dropdown-item" :style="option.style" @click="() =>  { option.action(); open = false }">
          {{option.name}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: [Object, Array], // can be either.
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Select'
    },
    buttonClass: {
      type: String,
      required: false,
      default: 'is-accent'
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    close () {
      this.open = false
    }
  }
}
</script>

<style>
  .dropdown-container {
    position: relative;
    width: auto;
  }

  .button-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    width: 100%;
  }

  .button-dropdown > span:nth-child(2) {
    margin-left: 0.5rem;
  }

  .button-dropdown-container {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9999;
    float: left;
    width: auto;
    min-width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: white;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    transition: opacity 0.15s linear;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .button-dropdown-container button {
    width: 100%;

    background-color: #fff;
    background-clip: padding-box;
    border-top: 1px solid #cecece!important;
    border-radius: 0!important;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .button-dropdown-container button:first-of-type {
    border: 0!important;
  }
</style>
